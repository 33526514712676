<template>
    <div class="agency-slider">
		<div class="container">
			<div class="section-title">
				<h2>Trusted by Agencies<br>
					& Store Owners</h2>				
			</div>
			<div class="agency-slider-section">
				<div class="fix-images">
					<img src="../assets/images/first-left.png" class="first">
					<img src="../assets/images/second-left.png" class="second">
					<img src="../assets/images/third-left.png" class="third">
					<img src="../assets/images/first-right.png" class="forth" >
					<img src="../assets/images/second-right.png" class="fifth">
					<img src="../assets/images/right-third.png" class="sixth">
				</div>
				<VueSlickCarousel v-bind="settings" class="agency-slide">
					<div class="agency-content">
						<h3>"The customer service was amazing and the pick-up was as fast as Flash! Thank you for making the whole process easier than I expected."</h3>
					</div>
				</VueSlickCarousel>
			</div>	
		</div>
	</div>
</template>
<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
 
  export default {
    name: 'AgencySlider',
    data() {
      return {
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            dots: false,
			arrows: false,
        },
      }
    },
    components: { VueSlickCarousel },
  }
</script> 